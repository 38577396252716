import { onWechatLogin } from '../api';
export const isWehat = function() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
    }
    return false;
}
export const onLogin = async function(code, fullPath) {
    let url = '';
    let res = {};
    try {
        res = await onWechatLogin({
            publicType: localStorage.getItem('publicType'),
            code: code
        });
        // if (res.code == 200) {
        //     localStorage.setItem('token', res.data.token);
        //     url = fullPath.replace('/personal?url=', '');
        // } else if (res.code == 1004) {
        //     // 激活页,无需登陆
        //     if (fullPath.search(/\/rights\?/) > -1){
        //         // $router.push(fullPath);
        //         url = fullPath;
        //     } else {
        //         // 1004 需要注册
        //         url = '/login';
        //     }
        // } 

    } catch(err) {
        console.log('err', err);
    }
    // return url;
    return res;
  }