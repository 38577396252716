<template>

<div>
    <div class="card">
    <div v-if="showFormTable">
        <div class="minipro">
            <div class="stepList">
                <p class="active_name">护理卡结算业务支付</p>
            </div>
           
    </div>
    <div class="card_info">
        <div class="title">
            <div class="tit-con">
                <i style="background: rgb(0, 93, 204);"></i>
                <span>个人信息</span>
            </div>
        </div>
        <van-form ref="form">
            <van-field
                v-model="personName"
                type="personName"
                name="personName"
                label="付款人姓名"
                placeholder="付款人姓名"
                :rules="[{ required: true, message: '请填写付款人姓名' }]"
            />
            <van-field
                v-if="orgId != 600 && orgId != 531"
                v-model="personPhone"
                type="personPhone"
                name="personPhone"
                label="付款人手机号(选填)"
                placeholder="付款人手机号(选填)"
                :rules="[{ required: false, message: '请填写付款人手机号' }]"
            >
            </van-field>
           
            <van-field
                v-if="provinceCode != '150000' && orgId != null && (orgId != 600 && orgId != 531) && supportProduct.length == 0"
                v-model="saleOrgName"
                type="saleOrgName"
                name="saleOrgName"
                label="机构名称(支公司层级)"
                placeholder="请填写机构名称"
                readonly
                :rules="[{ required: true, message: '请填写机构名称' }]"
            >
            </van-field>

            <van-field
                v-if="provinceCode != '150000' && orgId == null && (orgId != 600 && orgId != 531) && supportProduct.length == 0"
                v-model="saleOrgName"
                type="saleOrgName"
                name="saleOrgName"
                label="机构名称(支公司层级)"
                placeholder="请填写机构名称"
                :rules="[{ required: true, message: '请填写机构名称' }]"
            >
            </van-field>

            <van-field
                v-if="provinceCode == '150000' && companyId == '5'"
                v-model="saleOrgName"
                type="saleOrgName"
                name="saleOrgName"
                label="机构名称"
                placeholder="请填写机构名称"
                :rules="[{ required: true, message: '请填写工号' }]"
            >
            </van-field>


            <van-field
                v-if="orgId != null && (orgId == 658 || orgId == 1297 || orgId == 1309)"
                v-model="salePersonNo"
                type="salePersonNo"
                name="salePersonNo"
                label="工号"
                placeholder="请填写工号"
                :rules="[{ required: true, message: '请填写工号' }]"
            >
            </van-field>

            <van-field
                v-if="provinceCode == '150000' && companyId == '5'"
                v-model="salePersonNo"
                type="salePersonNo"
                name="salePersonNo"
                label="工号"
                placeholder="请填写工号"
                :rules="[{ required: true, message: '请填写工号' }]"
            >
            </van-field>



            <div v-if="productGroupList.length != 0">
            <van-cell required title="选择产品" :value="productGroupName" @click="showHospital = true" is-link />
            <van-popup v-model="showHospital" position="bottom">
                <van-picker
                show-toolbar
                :columns="productGroupList"
                @confirm="onConfirmNationality"
                @cancel="showHospital = false"
                />     
            </van-popup>
            </div>
            <van-field
                v-if="supportProduct.length == 0"
                v-model="price"
                type="price"
                name="price"
                label="付款金额"
                placeholder="请填写付款金额"
                :rules="[{ required: true, message: '请填写付款金额' }]"
            >
            </van-field>
            <van-field
                v-if="orgId != 600 && orgId != 531 && supportProduct.length == 0"
                v-model="remark"
                type="remark"
                name="remark"
                label="备注（卡种+数量）"
                placeholder="请填写备注"
                :rules="[{ required: true, message: '请填写备注（卡种+数量）' }]"
            >
            </van-field>
            <div style="height:100%;" v-if="supportProduct.length != 0">
                <div class="card_fee_div" style="height:100px;" v-for="(item, itemIndex) in supportProduct" :key="itemIndex">
                    <div class="card_fee" >{{item.productName}} <br/> <span v-if="orgId != 1211" style="color: red;">¥ {{item.price}} / 张</span></div>
                    <van-stepper min="0" @change="calcPrice" v-model="selectCountArr[itemIndex]" />
                </div>
            </div>
            
        </van-form>
    </div>
    <van-overlay :show="loading" >
        <van-loading size="40" vertical class="loadingDiv">支付中...</van-loading>
    </van-overlay>
    <div style="margin: 16px;">
        <div v-if="supportProduct.length == 0">
            <van-button  :loading="loading" round block type="info" @click="onHandleCheckForm">确认并付款</van-button>
        </div>
        <div style="width:100%;height:100px;" v-if="supportProduct.length != 0">
            <div style="font-size:16px;margin-top:8px;text-align: center;margin-bottom:10px;" >应付金额：<span style="color: red;">¥ {{price}}</span></div>
            <div ><van-button  :loading="loading" round block type="info" @click="onHandleCheckForm">确认并付款</van-button></div>
        </div>
    </div>
    </div>
    <van-popup
        v-model="show"
        round
        overlay-class="info-mask"
        closeable
        close-icon="close">
        <div class="info-mask_content">
            <h4 class="text-center">温馨提示</h4>
            <p>{{errorMsg}}</p>
            <p v-if="productCode != 'HPV1'">也可拨打客服电话确认。</p>
            <p v-if="productCode != 'HPV1'">
               <a class="tel_phone" :href="'tel:' + 4000133558">400-013-3558</a>
            </p>
            <p v-if="productCode == 'HPV1'">也可联系健康顾问确认。</p>
        </div>
    </van-popup>
    <!-- 内容 -->
    <van-popup
        v-model="showFlag"
        round
        closeable
        position="bottom"
        close-icon="close"
        :style="{ height: '70%' }">
        <div class="text-dialog flex flex-column" v-if="currentAgreement">
            <h2>{{ currentAgreement.agreementName }}</h2>
            <div class="text-content flex-1" v-html="currentAgreement.agreementContent"></div>
        </div>
    </van-popup>

    </div>
</div>
</template>
<script>
import { Toast, Dialog, Loading, Overlay } from 'vant';
import { areaList } from '@vant/area-data';
import { onFetchMessage, onLoginOrRegister, selPayOrgInfoByWxCode, getPayConfigByWechat, getSupportProductByOrgId } from '@/api';
import { weixinInit } from '@/utils/wechatUtil.js';
import wx from "weixin-sdk-js"
export default {
    data() {
        return {
            flag: false, // 新用户注册后，后端是否需要验证
            loading: false,
            showFormTable: true,
            showFlag: false,
            radioValue: '',
            btnDisabled: true,
            registerFlag: false, // 注册 modal
            isHealthyInform: false, // 是否弹出协议
            healthyInform: null, // 协议内容
            content: '',
            showHospital: false,
            show: false,
            errorMsg: '',
            checked: false,
            areaList,
            productCode: '', // 权益卡编码
            productPassword: '', // 卡密
            personName: "",
            credentialsType: 1, // 身份证类型   1 身份证 2 护照 3 港澳内地通行证 4 台胞证
            credentialsTypeName: '身份证',
            credentialsNumber: "",
            personPhone: "",
            salePersonName: "",
            salePersonNo: "",
            securityCode: "测试验证",
            regionCode: "",
            cityValue: '', // 所在城市
            time: 60,
            btnStatus: true,
            btnText: '获取验证码',
            cityVisible: false, // city flag
            showPicker: false,
            columns: ['身份证'],
            agreementLists: [], // 协议列表
            currentAgreement: null, // 选中的协议,
            publicType: '',
            price: '0',
            remark:'',
            saleOrgName: '1',
            urlString:'',
            orgId:'',
            supportProduct: [],
            productGroupList:[],
            selectCountArr: [],
            productGroupName: '',
            payeeType: 9,
            wxCode:'',
            companyName:'',
            provinceCode:'',
            companyId:'',
        }
    },
    created() {
        this.productCode = this.$route.query.productCode || '';
        this.wxCode = this.$route.query.wxCode || '';
        this.publicType = 4;
        this.urlString = window.location.href.split('#')[0];
        this.orgId = localStorage.getItem('orgId');
        this.init();
        this.wxConfigInit();
    },
    methods: {
        calcPrice() {
            const arr = this.selectCountArr.map((item, index) => item * this.supportProduct[index].price);
            this.price = arr.reduce((cur, total) => {
                return cur + total;
            }, 0);
            this.remark = "";
            this.selectCountArr.forEach((v, i) => {
                this.remark += this.supportProduct[i].productName + "数量" + v + "  ";
            })
        },
        goPage() {
            if (this.radioValue == '1') {
                Dialog.alert({
                    title: '温馨提示',
                    message: '非常抱歉，您暂不符合激活条件。',
                    theme: 'round-button',
                    confirmButtonColor: '#1989fa'
                }).then(() => {
                // on close
                });
            } else {
                // this.$router.push('/progressStep2');
                this.registerFlag = false;
                this.showFormTable = true;
                //this.onHandleFinalSumit();
            }
        },
        onConfirmNationality(value) {
            this.productGroupName = value;
            this.showHospital = false;
        },
        async wxConfigInit() {
            let weixinShare = {
                publicType: 1,
                url: this.urlString
            }
            await weixinInit(weixinShare);
        },
        // 是否可以点击下一步
        onHandleChange() {
            this.btnDisabled = false;
        },
        onShow(index) {
            this.currentAgreement = this.agreementLists[index];
            this.showFlag = true;
        },
        // 发送验证码
        async onHandleGetMessage() {
            if (!this.btnStatus) {
                return false;
            }
            if (!this.personPhone) {
                Toast('请先输入手机号');
                return;
            }
            if (this.personPhone.length != 11) {
                Toast('请输入正确的手机号');
                return;
            }
            try {
                const res = await onFetchMessage({
                    userPhone:this.personPhone,
                    publicType: this.publicType
                });
                Toast('短信已发送');
                console.log('res', res);
                this.btnStatus = false;
                let timer = setInterval(() => {
                    if (--this.time > 0) {
                        this.btnText = `${this.time}s重新发送`;
                    } else {
                        this.btnText = '获取验证码';
                        clearInterval(timer);
                        this.btnStatus = true;
                        this.time = 60;
                    }
                }, 1000);
            } catch(err) {
                this.btnStatus = true;
                console.log('err', err);
            }
        },
        async init() {
            try {
                let code = this.wxCode;
                let orgIdParam = this.orgId;
                let orgPayInfo = '';
                if(code){
                    const res = await selPayOrgInfoByWxCode({
                        "code": this.wxCode,
                        "orgId": orgIdParam,
                        "publicType": this.publicType
                    });
                    if (res.code == 200) {
                        orgPayInfo = res.data;
                    }
                }
                if(orgPayInfo != ''){
                    this.personName = orgPayInfo.personName;
                    this.personPhone = orgPayInfo.personPhone;
                    this.provinceCode = orgPayInfo.provinceCode;
                    this.companyId = orgPayInfo.companyId;
                    this.saleOrgName = orgPayInfo.saleOrgName;
                    this.productGroupList = orgPayInfo.productGroupList;
                }
            } catch(err) {
                console.log('err', err);
            }
            if(this.orgId != null && this.orgId != '') {
                try {
                let orgIdParam = this.orgId;
                const res = await getSupportProductByOrgId({
                    "orgId": orgIdParam
                });
                if (res.code == 200) {
                    this.supportProduct = res.data;
                    this.supportProduct.forEach((v, i) => {
                        this.selectCountArr[i] = 0;
                    })
                }
            } catch(err) {
                console.log('err', err);
            }
            }
        },
        onHandleCheckForm() {
            if(this.price == 0){
                Toast('应付金额不能为0');
                return false;
            }
            if(this.orgId == null || this.orgId == '') {
                Toast('当前获取到的机构信息为空，请重新扫码进入支付');
                return false;
            }

            if(this.orgId == 168) {
                Toast('暂不支持付款，详情请联系客服');
                return false;
            }

            if(this.productGroupList.length != 0 && this.productGroupName == ''){
                Toast('请选择要支付的产品类型');
                return false;
            }

            if(this.orgId != 600 && this.orgId != 531) {
                if(!this.personPhone) {
                    this.personPhone = "00000000000";
                }
                if (!this.personName || !this.personPhone || !this.securityCode || !this.price || !this.saleOrgName || 
                    !this.remark) {
                    Toast('存在未填写的信息,请检查');
                    return false;
                }
            }else{
                if (!this.price || !this.personName) {
                    Toast('存在未填写的信息,请检查');
                    return false;
                }
            }
            this.loading = true;
            this.$refs.form.submit();
            const token = localStorage.getItem('token');
            console.log('token', token)
            this.onSubmit();
            
            
        },
        // 注册新用户
        async onRegister() {
            try {
                const params = {
                    userPhone: this.personPhone,
                    securityCode: this.securityCode,
                    publicType: 1
                };
                let code = this.wxCode;
                if (code) {
                    Object.assign(params, {
                        code
                    })
                }
                const res = await onLoginOrRegister(params);
                if (res.code == 200) {
                    localStorage.setItem('token', res.data.token);
                    this.flag = true;
                    // 提交表单
                    this.onSubmit();
                } else {
                    this.$toast(res.msg);
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        // 提交表单
        async onSubmit() {
            
            
            this.onHandleFinalSumit();
        },
        //唤起微信支付
        async weixinPay(weixinPayParam) {
            if (typeof WeixinJSBridge == "undefined"){
                if( document.addEventListener ){
                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                }else if (document.attachEvent){
                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                }
            }else{
                this.onBridgeReady(weixinPayParam);
            }
        },
        async onBridgeReady(weixinPayParam){
            let payNoDiy = weixinPayParam.payNo;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                    "appId": weixinPayParam.appId,
                    "timeStamp": weixinPayParam.timeStamp,
                    "nonceStr": weixinPayParam.nonceStr,     
                    "package": weixinPayParam.packages,     
                    "signType": weixinPayParam.signType,    
                    "paySign": weixinPayParam.paySign
                },
                function(res){
                    window.location.href = 'https://www.healthmore.net/paysuccess?payNo=' + payNoDiy;
                    //this.$router.push(`/paysuccess?payNo=${payNoDiy}`);
                })
        },
        // 提交接口
        async onHandleFinalSumit() {
            let code = this.wxCode;
            let values = {
                personName: this.personName,
                personPhone: this.personPhone,
                saleOrgName: this.saleOrgName,
                saleOrgId: this.orgId,
                price: this.price,
                remark: this.remark,
                publicType: this.publicType,
                wxCode: code,
                salePersonNo: this.salePersonNo,
                payeeType: this.payeeType,
                productGroupName: this.productGroupName,
                companyName: this.companyName
            }
            console.log('submit', values);
            try {
                const res = await getPayConfigByWechat(values);
                console.log('res', res);
                if (res.code == 200) {
                    this.weixinPay(res.data);
                } else {
                    this.$toast(res.msg);
                    this.loading = false;
                }
            } catch(err) {
                console.log('err', err);
                this.loading = false;
            }
            this.registerFlag = false;
        },
        onCitySelect(item) {
            console.log('item', item);
        },
    }
}
</script>
<style lang="less" scoped>

::v-deep .health-content {
    padding: 10px 10px;
    font-size: 14px;
    line-height: 16px;
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    img {
        width: 100%;
    }
}
::v-deep .health-options {
    padding: 10px;
    .explian {
        font-size: 14px;
        span {
            padding-right: 10px;
        }
    }
    .btn-options {
        margin-top: 25px;
        
    }
}
p {
    margin: 0;
    padding: 0;
}
.card {
    padding: 0 15px;
}
.banner {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.tel_phone {
    font-size: 25px;
}
.minipro {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    .stepList {
        flex: 1;
        position: relative;
        .num {
            background: #fff;
            border-radius: 50%;
            color: #ccc;
            border: 1px solid #ccc;
        }
        .active_num,
        .num {
            width: 30px;
            height: 30px;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            line-height: 30px;
            margin: 0 auto;
        }
        .active_num {
            background: #005dcc;
            border-radius: 50%;
            color: #fff;
        }
        .active_name,
        .name {
            font-size: 12px;
            font-weight: 500;
            margin-top: 7px;
            text-align: center;
            line-height: 18px;
        }
        .active_name {
            color: #005dcc;
            font-size: 20px;
        }
        img {
            width: 12px;
            position: absolute;
            right: -6px;
            top: 10px;
            border-style: none;
        }
    }
}
.form-box {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
    margin-bottom: 13px;
}
.card_fee_div{
    height:50px;
}
.card_fee{
    font-size: 15px;
    width: 170px;
    float: left;
    margin-right: 40px;
    height: 50px;
    padding-top: 5px;
}
.card_info {
    width: 100%;
    padding: 16px 0 0;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
    margin-bottom: 12px;
    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        display: flex;
        .tit-con {
            flex: 1;
            display: flex;
            align-items: center;
            i {
                display: inline-block;
                vertical-align: middle;
                margin-right: 12px;
                width: 4px;
                height: 20px;
                background: #005dcc;
                border-radius: 2px;
            }
            span {
                vertical-align: middle;
                line-height: .26rem;
            }
        }
    }
}
.card .van-form {
    padding: 0 16px;
    .van-field {
        padding: 16px 0;
    }
}
::v-deep .van-field .van-field__error-message {
    line-height: 12px;
    position: absolute;
    bottom: -10px;
    left: 0;
}
.van-field__error-message{
    color: #ee0a24;
    font-size: 12px;
    text-align: left;
}
.btn-verify {
    display: inline-block;
    font-size: 12px;
    background-color: #1989fa;
    color: #fff;
    padding: 5px 10px;
    border-radius: 30px;
    &.disabled {
        opacity: .7;
    }
}
.popup-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .van-cell__title {
        color: #646566;
    }
    .van-cell__value {
        color: #323233;
    }
}
.tips {
    font-size: 12px;
    color: #999;
    margin: 12px auto;
    text-align: center;
}
.loadingDiv {
    position: absolute;
    left: 42%;
    top: 40%;
}
.agreement {
    margin-top: 20px;
    align-items: flex-start;
    font-size: 12px;
    color: #1d1d1d;
    .text-link {
        color:rgb(0, 93, 204);
    }
}

::v-deep .info-mask {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 300px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            text-align: center;
        }
        .qrcode {
            width: 150px;
            height: 150px;
            margin: 10px auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.text-dialog {
    height: 100%;
    color: #333;
    
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    .text-content {
        padding: 30px 10px;
        font-size: 14px;
        line-height: 22px;
        overflow: auto;
    }
}
</style>