<template>
    <div class="serverPdf">
    	<div>
			<div class="divText"><img src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ec4a0557-5a60-44c8-add4-8b09a12ea2af.jpg"></div>
			<div class="divText">甲方：{{personName}}</div>
			<div class="divText">身份证号：{{idCardNo}}</div>
			<div class="divText">服务卡号：{{activationCardNumber}}</div>
			<div class="divText">电话：{{personPhone}}</div>
			<div class="divText" style="height: 15px;"></div>
			<div class="divText">乙方：上海壹树医疗科技有限公司</div>
			<div class="divText">地址：中国(上海)自由贸易试验区纳贤路 800 号 1 幛  B 座 9 楼 902-2</div>
			<div class="divText"></div>
		</div>
		<img @click="preview(0)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/b4ea09e5-1ff7-48fc-9ca7-5cc59f93917b.jpg">
        <img @click="preview(1)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/be84ce3a-e1b3-45ef-ac27-1ef9703b87ad.png">
        <img @click="preview(2)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ddcd7e76-1390-4606-91f2-6eb00a1b3a23.png">
        <img @click="preview(3)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/d8979118-b3b6-4c21-a252-666bcbe6f395.png">
        <img @click="preview(4)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/e56d50a4-88ba-493e-99b0-07584d1877e1.png">
        <img @click="preview(5)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/fca3cd17-5b6b-497f-927d-424f90cf3d31.png">
        <img @click="preview(6)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/fc9a4574-4ca4-4928-a4d7-70bcb1c23732.png">
        <img @click="preview(7)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/8fc6d451-8b9e-4e88-8e28-ad532061c487.png">
        <img @click="preview(8)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/77364728-2746-42bb-8604-d431d8d9cf52.png">
        <img @click="preview(9)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/7d894e2b-8698-471a-ac76-2bedfc43d2fe.png">
        <img @click="preview(10)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/1cd3873f-4f45-4e53-bf35-9909841ad98e.png">
        <img @click="preview(11)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/35572a24-8451-4e68-b4f2-5cdd8d2049a2.png">
        <img @click="preview(12)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/8fe858c2-248b-4e7b-89fb-c2340894d3e4.png">
        <img @click="preview(13)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/6721dfde-0e7e-44f6-a986-036847aa135f.png">
        <img @click="preview(14)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/6fe6f81f-f065-4cf0-af36-76074e5d3e28.png">
        <img @click="preview(15)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/989c3c3c-cf89-4ecd-9382-8f19e9009929.png">
    </div>
</template>

<script>
import { contents } from './util';
import { ImagePreview } from 'vant';
import { weixinInit } from '@/utils/wechatUtil.js';
import { selUserPrivilegeDetail  } from '@/api';
export default {
    data() {
        return {
           personName: '',
           idCardNo: '',
           activationCardNumber: '',
           personPhone: '',
           personPrivilegeId: '',
        }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.init();
    },
    methods: {
        async preview(index) {
            ImagePreview({
            images:[
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/b4ea09e5-1ff7-48fc-9ca7-5cc59f93917b.jpg',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/be84ce3a-e1b3-45ef-ac27-1ef9703b87ad.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/ddcd7e76-1390-4606-91f2-6eb00a1b3a23.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/d8979118-b3b6-4c21-a252-666bcbe6f395.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/e56d50a4-88ba-493e-99b0-07584d1877e1.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/fca3cd17-5b6b-497f-927d-424f90cf3d31.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/fc9a4574-4ca4-4928-a4d7-70bcb1c23732.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/8fc6d451-8b9e-4e88-8e28-ad532061c487.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/77364728-2746-42bb-8604-d431d8d9cf52.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/7d894e2b-8698-471a-ac76-2bedfc43d2fe.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/1cd3873f-4f45-4e53-bf35-9909841ad98e.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/35572a24-8451-4e68-b4f2-5cdd8d2049a2.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/8fe858c2-248b-4e7b-89fb-c2340894d3e4.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/6721dfde-0e7e-44f6-a986-036847aa135f.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/6fe6f81f-f065-4cf0-af36-76074e5d3e28.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/989c3c3c-cf89-4ecd-9382-8f19e9009929.png'
            ],
            startPosition: index,
            closeable: true,
        });
        },
        async init() {
            try {
                const res = await selUserPrivilegeDetail({
                    personPrivilegeId: this.personPrivilegeId
                });
                if (res.code == 200) {
                    this.personName = res.data[0].personName;
                    this.idCardNo = res.data[0].credentialsNumber;
                    this.activationCardNumber = res.data[0].activationCardNumber;
                    this.personPhone = res.data[0].personPhone;
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style scoped lang="less">
.serverPdf {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.divText {
    margin-left: 25px;
    width: 330px;
    font-size: 10px;
    line-height: 20px;
}
</style>