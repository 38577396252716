<template>
    <div class="serverPdf">
    	<div>
			<div class="divText"><img src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ec4a0557-5a60-44c8-add4-8b09a12ea2af.jpg"></div>
			<div class="divText">甲方：{{personName}}</div>
			<div class="divText">身份证号：{{idCardNo}}</div>
			<div class="divText">服务卡号：{{activationCardNumber}}</div>
			<div class="divText">电话：{{personPhone}}</div>
			<div class="divText" style="height: 15px;"></div>
			<div class="divText">乙方：上海壹树医疗科技有限公司</div>
			<div class="divText">地址：中国(上海)自由贸易试验区纳贤路 800 号 1 幛  B 座 9 楼 902-2</div>
			<div class="divText"></div>
		</div>
        <img @click="preview(0)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/b4ea09e5-1ff7-48fc-9ca7-5cc59f93917b.jpg">
        <img @click="preview(1)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/f7b63c0d-82e2-4800-be67-322f0015c0d6.png">
        <img @click="preview(2)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/f951b63d-15a2-4a77-b4db-3f474d8cbf98.png">
        <img @click="preview(3)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/9d960e9a-ae86-439a-8784-a05d5945a7e0.png">
        <img @click="preview(4)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/fba91e5f-eb7b-4e9b-a059-190af0cd580b.png">
        <img @click="preview(5)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/909a6407-edd6-4ddb-be3b-c6049670c14f.png">
        <img @click="preview(6)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/222d1d69-7af3-43cc-8dd9-ae253778896d.png">
        <img @click="preview(7)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/9fd30cb7-5bba-4484-ae42-d9881a2d2573.png">
        <img @click="preview(8)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/b02877e4-aac3-40ec-b7eb-5f4c7ea5d446.png">
        <img @click="preview(9)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/5949e3d6-775b-45c3-bee7-48612893baf9.png">
        <img @click="preview(10)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/800f1a1e-4e9e-43cc-b038-c2a42df28fab.png">
        <img @click="preview(11)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/7c29221f-bfa1-49e9-a1eb-98e0bd6ed770.png">
        <img @click="preview(12)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/b7a147d6-4432-4920-9eb9-03d7573e4584.png">
        <img @click="preview(13)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/bb43e81a-2ed8-4764-b732-17d07c1dc5a3.png">
        <img @click="preview(14)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/015cbd37-edb9-4324-81c7-26c63eddfdef.png">
        <img @click="preview(15)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/b6a5af18-598c-4912-8541-9e210d572e4a.png">
        <img @click="preview(16)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ebf555e5-9d9f-4044-92ab-561ae2276ec2.png">
        <img @click="preview(17)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/67ec325e-f9ea-423b-8421-47cb978fb6e0.png">
        <img @click="preview(18)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/08f67ca8-6e85-4a18-9e69-e5815e256e79.png">
        <img @click="preview(19)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/5aa730a6-0959-4eff-b268-752279d3f434.png">
        <img @click="preview(20)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/ee9d2e8a-f395-481b-936d-1c697d38461a.png">
        <img @click="preview(21)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/32de39a6-4f70-4770-a168-d3a01bc94e1a.png">
        <img @click="preview(22)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/cbce0408-c881-483d-92b6-4620d603c404.png">
        <img @click="preview(23)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/12197693-8403-433d-a0c5-b02d4804317e.png">
        <img @click="preview(24)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/14464719-e90b-441a-8752-17ede2894d45.png">
        <img @click="preview(25)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/2b2b2263-290c-4402-8aaa-17bbe8b7f161.png">
        <img @click="preview(26)" src="https://more-health.oss-cn-beijing.aliyuncs.com/image/5f0affe5-3a08-4906-8d1a-691bef2ce984.png">
    </div>
</template>

<script>
import { contents } from './util';
import { ImagePreview } from 'vant';
import { weixinInit } from '@/utils/wechatUtil.js';
import { selUserPrivilegeDetail  } from '@/api';
export default {
    data() {
        return {
           personName: '',
           idCardNo: '',
           activationCardNumber: '',
           personPhone: '',
           personPrivilegeId: '',
        }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.init();
    },
    methods: {
        async preview(index) {
            ImagePreview({
            images:[
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/b4ea09e5-1ff7-48fc-9ca7-5cc59f93917b.jpg',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/f7b63c0d-82e2-4800-be67-322f0015c0d6.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/f951b63d-15a2-4a77-b4db-3f474d8cbf98.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/7e6cb6af-e412-4a51-9804-40c7db9fec5d.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/9d960e9a-ae86-439a-8784-a05d5945a7e0.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/fba91e5f-eb7b-4e9b-a059-190af0cd580b.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/909a6407-edd6-4ddb-be3b-c6049670c14f.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/222d1d69-7af3-43cc-8dd9-ae253778896d.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/9fd30cb7-5bba-4484-ae42-d9881a2d2573.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/b02877e4-aac3-40ec-b7eb-5f4c7ea5d446.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/5949e3d6-775b-45c3-bee7-48612893baf9.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/800f1a1e-4e9e-43cc-b038-c2a42df28fab.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/7c29221f-bfa1-49e9-a1eb-98e0bd6ed770.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/b7a147d6-4432-4920-9eb9-03d7573e4584.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/bb43e81a-2ed8-4764-b732-17d07c1dc5a3.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/015cbd37-edb9-4324-81c7-26c63eddfdef.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/b6a5af18-598c-4912-8541-9e210d572e4a.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/ebf555e5-9d9f-4044-92ab-561ae2276ec2.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/67ec325e-f9ea-423b-8421-47cb978fb6e0.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/08f67ca8-6e85-4a18-9e69-e5815e256e79.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/5aa730a6-0959-4eff-b268-752279d3f434.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/ee9d2e8a-f395-481b-936d-1c697d38461a.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/32de39a6-4f70-4770-a168-d3a01bc94e1a.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/cbce0408-c881-483d-92b6-4620d603c404.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/12197693-8403-433d-a0c5-b02d4804317e.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/14464719-e90b-441a-8752-17ede2894d45.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/2b2b2263-290c-4402-8aaa-17bbe8b7f161.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/image/5f0affe5-3a08-4906-8d1a-691bef2ce984.png'
            ],
            startPosition: index,
            closeable: true,
        });
        },
        async init() {
            try {
                const res = await selUserPrivilegeDetail({
                    personPrivilegeId: this.personPrivilegeId
                });
                if (res.code == 200) {
                    this.personName = res.data[0].personName;
                    this.idCardNo = res.data[0].credentialsNumber;
                    this.activationCardNumber = res.data[0].activationCardNumber;
                    this.personPhone = res.data[0].personPhone;
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style scoped lang="less">
.serverPdf {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.divText {
    margin-left: 25px;
    width: 330px;
    font-size: 10px;
    line-height: 20px;
}
</style>